import { render, staticRenderFns } from "./ThesisExamRegistration.vue?vue&type=template&id=72a55d9f&"
import script from "./ThesisExamRegistration.vue?vue&type=script&lang=js&"
export * from "./ThesisExamRegistration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports